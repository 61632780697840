import {
  BrowserRouter as Router,
  Route,
  Routes,
  useLocation,
} from "react-router-dom";
import Administration from "./administration/Administration";
import PrivateRoute from "./route-protection/PrivateRoute";
import PublicRoute from "./route-protection/PublicRoute";
import { Dashboard } from "./profiles-management/Dashboard";
import Login from "./authentication/Login";
import Init from "./authentication/Init";
import ProfileView from "./profiles-management/ProfileView";
import RealEncounters from "./real-encounters/RealEncounters";
import Reports from "./reports/Reports";
import { AdminRole } from "./data/model/enumerations";
import AdminUsers from "./administration/AdminUsers";
import AppConfiguration from "./administration/Configuration";
import CustomHobbies from "./administration/CustomHobbies";
import Hobbies from "./administration/Hobbies";
import ProfilesManagement from "./profiles-management/ProfilesManagement";
import TwoFactorAuthentication from "./administration/TwoFactorAuthentication";
import Verify2FA from "./authentication/Verify2FA";
import TwoFactorAuthRoute from "./route-protection/TwoFactorAuthRoute";
import { parseJwt } from "./helpers/jwt-helper";
import IcebreakerConfiguration from "./administration/IcebreakerConfiguration";
import IcebreakerTemplates from "./administration/IcebreakerTemplates";
import IcebreakerTestingPanel from "./administration/IcebreakerTestingPanel";
import Enforced2FASetupRoute from "./route-protection/Enforced2FASetupRoute";
import Enforced2FASetup from "./authentication/Enforced2FASetup";
import AccountDeletion from "./authentication/AccountDeletion";

function App() {
  // If the token is expired, remove it.
  const accessToken = localStorage.getItem("access_token");
  if (accessToken != null) {
    const payload = parseJwt(accessToken);
    const expirationDate = new Date(0); // The 0 there is the key, which sets the date to the epoch
    expirationDate.setUTCSeconds(payload.exp);
    if (expirationDate < new Date()) {
      localStorage.clear();
      const location = useLocation();
      console.log(location.pathname);
      localStorage.setItem("intendedRoute", location.pathname);
    }
  }

  return (
    <>
      <Router>
        <Routes>
          <Route path="/" element={<PrivateRoute />}>
            <Route path="/" element={<ProfilesManagement />}>
              <Route path="/" element={<Dashboard />} />
              <Route path="/profile/:profileId" element={<ProfileView />} />
            </Route>
          </Route>
          <Route path="/administration" element={<PrivateRoute />}>
            <Route path="/administration" element={<Administration />}>
              <Route
                path="/administration/configuration"
                element={<AppConfiguration />}
              />
              <Route
                path="/administration/admin-users"
                element={
                  <PrivateRoute allowedRoles={[AdminRole.SUPER_ADMIN]} />
                }
              >
                <Route
                  path="/administration/admin-users"
                  element={<AdminUsers />}
                />
              </Route>
              <Route path="/administration/hobbies" element={<Hobbies />} />
              <Route
                path="/administration/custom-hobbies"
                element={<CustomHobbies />}
              />
              <Route
                path="/administration/two-factor-authentication"
                element={<TwoFactorAuthentication />}
              />
              <Route
                path="/administration/icebreaker-configuration"
                element={<IcebreakerConfiguration />}
              />
              <Route
                path="/administration/icebreaker-templates"
                element={<IcebreakerTemplates />}
              />
              <Route
                path="/administration/icebreaker-testing-panel"
                element={<IcebreakerTestingPanel />}
              />
            </Route>
          </Route>

          <Route
            path="/reports"
            element={
              <PrivateRoute
                allowedRoles={[
                  AdminRole.SUPER_ADMIN,
                  AdminRole.ADMIN,
                  AdminRole.DEV_ADMIN,
                  AdminRole.MODERATOR,
                ]}
              />
            }
          >
            <Route path="/reports" element={<Reports />} />
          </Route>

          <Route
            path="/real-encounters"
            element={
              <PrivateRoute
                allowedRoles={[AdminRole.SUPER_ADMIN, AdminRole.DEV_ADMIN]}
              />
            }
          >
            <Route path="/real-encounters" element={<RealEncounters />} />
          </Route>
          <Route path="/login" element={<PublicRoute />}>
            <Route path="/login" element={<Login />}></Route>
          </Route>

          <Route path="/delete-account" element={<Init />}></Route>
          <Route path="/account-deletion" element={<AccountDeletion />}></Route>

          <Route path="/two-factor-auth" element={<TwoFactorAuthRoute />}>
            <Route path="/two-factor-auth" element={<Verify2FA />}></Route>
          </Route>
          <Route path="/enforced-2FA-setup" element={<Enforced2FASetupRoute />}>
            <Route
              path="/enforced-2FA-setup"
              element={<Enforced2FASetup />}
            ></Route>
          </Route>
        </Routes>
      </Router>
    </>
  );
}

export default App;
